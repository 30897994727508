// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconRocket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconBell,
    IconComponents,
    IconCalendarMinus,
    IconBox,
    IconBuildingCottage,
    IconApiApp
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconReceipt,
    IconRocket,
    IconShoppingCartDiscount,
    IconTicket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconBell,
    IconComponents,
    IconCalendarMinus,
    IconBox,
    IconBuildingCottage,
    IconApiApp
};

// ==============================|| Client PAGES MENU ITEMS ||============================== //

const clientPages = {
    id: 'client-pages',
    type: 'group',
    permissionID: 'All',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            url: '/dashboard',
            permissionID: 'All'
        },
        // {
        //     id: 'social-lead',
        //     title: <FormattedMessage id="contacts" />,
        //     type: 'item',
        //     icon: icons.IconRocket,
        //     url: '/contacts',
        //     permissionID: 'SocialMediaLead-read'
        // },
        {
            id: 'social-lead',
            title: <FormattedMessage id="contacts" />,
            type: 'collapse',
            icon: icons.IconRocket,
            permissionID: 'SocialMediaLead-read',
            children: [
                {
                    id: 'social-lead',
                    title: <FormattedMessage id="contact list" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/contacts',
                    permissionID: 'SocialMediaLead-read'
                },
                {
                    id: 'contactTracking',
                    title: <FormattedMessage id="contact tracking" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/contact-tracking',
                    permissionID: 'SocialMediaLead-read'
                }
            ]
        },
        // {
        //     id: 'the-radar',
        //     title: <FormattedMessage id="Radar" />,
        //     type: 'item',
        //     icon: icons.IconRocket,
        //     url: '/the-radar',
        //     permissionID: 'radar-read'
        // },

        {
            id: 'customers',
            title: <FormattedMessage id="Potential Customer" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/potential-customers',
            permissionID: 'Customer-read'
        },
        {
            id: 'cartons',
            title: <FormattedMessage id="cartons" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/cartons',
            permissionID: 'Carton-read'
        },
        {
            id: 'machines',
            title: <FormattedMessage id="machines" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/machines',
            permissionID: 'Machine-read'
        },

        {
            id: 'spare-parts',
            title: <FormattedMessage id="spare-parts" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/spareparts',
            permissionID: 'SparePart-read'
        },
        {
            id: 'quotation-request',
            title: <FormattedMessage id="quotes" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/quotes',
            permissionID: 'Quotes-read'
        },
        {
            id: 'Sample-request',
            title: <FormattedMessage id="samples" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/samples',
            permissionID: 'Sample-read'
        },

        // {
        //     id: 'orders',
        //     title: <FormattedMessage id="orders" />,
        //     type: 'item',
        //     icon: icons.IconUsers,
        //     url: '/orders',
        //     permissionID: 'order-read'
        // },
        {
            id: 'staff',
            title: <FormattedMessage id="astronauts" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/astronauts',
            permissionID: 'Astronaut-read'
        },

        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'item',
            icon: icons.IconChartBar,
            url: '/reports',
            permissionID: 'Report-read'
        },
        {
            id: 'careers',
            title: <FormattedMessage id="candidates" />,
            type: 'item',
            icon: icons.IconBell,
            url: '/careers',
            permissionID: 'Career-read'
        }
    ]
};

export default clientPages;
