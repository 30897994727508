import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
// ==============================|| MENU ITEMS ||============================== //

const useMenuItems = (menuItems) => {
    const { isLoggedIn, user } = useAuth();

    const filterPage = (page) => {
        if (page?.children?.length > 0) {
            const childs = page.children.filter((newPage) => {
                const fPage = filterPage(newPage);
                if (fPage) return fPage;
                return false;
            });
            page.children = childs;
        }

        if (page?.children?.length > 0 || user?.Role?.accessIDs.split(',').includes(page?.permissionID) || page?.permissionID === 'All') {
            return page;
        }
        return false;
    };

    const newPages = () => {
        if (user?.user_type === 'Admin' || user?.user_type === 'User') return menuItems.adminPages;
        if (user?.user_type === 'Staff') return filterPage(menuItems.clientPages);
        return filterPage(menuItems.adminPages);
        // return menuItems.adminPages;
    };
    return newPages();
};

export default useMenuItems;
